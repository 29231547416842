<header id="header" class="header-body header-effect-shrink" data-plugin-options="{'stickyEnabled': true, 'stickyEffect': 'shrink', 'stickyEnableOnBoxed': false, 'stickyEnableOnMobile': true, 'stickyStartAt': 70, 'stickyChangeLogo': false, 'stickyHeaderContainerHeight': 70}">
  <div class="header-body border-top-0 bg-light box-shadow"   style="background: #ffffff url(assets/img/control/icon-bg.svg) no-repeat right top;">
    <div class="header-container container">
      <div class="header-row">
        <div class="header-column">
          <div class="header-row">
            <div class="header-logo appear-animation appear-animation-visible" data-appear-animation="fadeIn" data-plugin-options="{'accY': 100}">
              <a href="#" class="goto-top">
                <img alt="Porto" width="auto" height="auto" data-sticky-width="82" data-sticky-height="36" data-sticky-top="0" src="assets/img/control/logo.svg">
              </a>
            </div>
          </div>
        </div>
        <div class="header-column justify-content-end">
          <div class="header-row">
            <div class="header-nav header-nav-links header-nav-light-text header-nav-dropdowns-dark">
              <div class="header-nav-main header-nav-main-square header-nav-main-dropdown-no-borders header-nav-main-text-capitalize header-nav-main-text-size-2 header-nav-main-mobile-dark header-nav-main-effect-1 header-nav-main-sub-effect-1 appear-animation appear-animation-visible" data-appear-animation="fadeIn" data-plugin-options="{'accY': 100}">
                <nav class="collapse">
                  <ul class="nav nav-pills" id="mainNav">
                    <li class="dropdown">
                      <a class="dropdown-item dropdown-toggle" routerLink="/">
                        Home
                      </a>
                    </li>
                    <li class="dropdown">
                      <a class="dropdown-item dropdown-toggle" data-hash data-hash-offset="130" routerLink="/about">
                        About
                      </a>
                    </li>
                    <li class="dropdown">
                      <a class="dropdown-item dropdown-toggle" data-hash data-hash-offset="130" routerLink="/services">
                        Services
                      </a>
                    </li>
<!--                    <li class="dropdown">-->
<!--                      <a class="dropdown-item dropdown-toggle" data-hash data-hash-offset="130" routerLink="/gallery">-->
<!--                        Gallery-->
<!--                      </a>-->
<!--                    </li>-->
                    <li class="dropdown">
                      <a class="dropdown-item dropdown-toggle" data-hash data-hash-offset="130" routerLink="/contact">
                        Contacts
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <button class="btn header-btn-collapse-nav" data-toggle="collapse" data-target=".header-nav-main nav"><i class="fa fa-bars"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
