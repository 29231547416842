import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators, FormArray, FormControl, FormGroup, NgForm} from '@angular/forms';
import {ConnectService} from '../shared/services/connect.service';
import {EmailValidators} from './email.validators';
import {Router} from '@angular/router';
import {SubscribeService} from '../shared/services/subscribe.service';

@Component({
  selector: 'app-connect',
  templateUrl: './connect.component.html',
  styleUrls: ['./connect.component.css']
})
export class ConnectComponent implements OnInit {

  form;
  formed;
  clicked;
  status;
  response;
  data;
  loading;
  constructor(fb: FormBuilder, private connectService: ConnectService, private subscribeService: SubscribeService, private router: Router) {
    // this.formed = fb.group({
    //   name: ['', [Validators.required, Validators.minLength(3)]],
    //   email: ['', [Validators.required, EmailValidators.cannotContainSpace],
    //   ],
    //   message: ['', [
    //     Validators.required,
    //     Validators.minLength(6)]]
    // });
    this.formed = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(3)]),
      email: new FormControl('', [Validators.required, Validators.email]),
      message: new FormControl('', [Validators.required, Validators.minLength(3)])
    });
  }

  ngOnInit(): void {
  }

  connect(form: NgForm): any {
    this.clicked = true;
    if (form.valid) {
      this.loading = true;
    }
    this.connectService.connect(this.formed.value).subscribe(
      data => {
        this.data = data;
        this.status = this.data.status;
        this.response = this.data.message;
        console.log(this.response);
        this.message.reset();
        this.clicked = false;
        this.formed.reset();
        this.loading = false;
      },
      (error: Response) => {
        console.log(error);
        this.clicked = false;
      }
    );
  }

  get name(): any {
    return this.formed.get('name');
  }
  get email(): any {
    return this.formed.get('email');
  }
  get message(): any {
    return this.formed.get('message');
  }

}
