import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators, FormArray, FormControl, FormGroup, NgForm} from '@angular/forms';
import {Router} from '@angular/router';
import {EmailValidators} from '../../../connect/email.validators';
import {ConnectService} from '../../services/connect.service';
import {SubscribeService} from '../../services/subscribe.service';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.css']
})
export class NewsletterComponent implements OnInit {
  form;
  formed;
  clicked;
  status;
  response;
  data;
  loading;

  constructor(fb: FormBuilder, private connectService: ConnectService, private subscribeService: SubscribeService, private router: Router) {
    this.formed = fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      cemail: ['', [Validators.required, EmailValidators.cannotContainSpace],
      ],
      message: ['', [
        Validators.required,
        Validators.minLength(6)]]
    });
    this.form = fb.group({
      email: ['', [Validators.required, EmailValidators.cannotContainSpace],
      ],
      name: ['', [Validators.required, Validators.minLength(3)],
      ]
    });
  }
  ngOnInit(): void {
  }
  subscribe(form: NgForm): any {
    this.clicked = true;
     if (form.valid) {
      this.loading = true;
    }
    this.subscribeService.subscribed(this.form.value).subscribe(
      data => {
        // this.form.value = '';
        this.data = data;
        this.status = this.data.status;
        this.response = this.data.message;
        this.clicked = false;
         this.loading = false;
      },
      (error: Response) => {
        console.log(error);
        this.clicked = false;
      }
    );
  }

  get email(): any {
    return this.form.get('email');
  }


}
