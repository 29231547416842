import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TopbarComponent} from './components/topbar/topbar.component';
import {BottombarComponent} from './components/bottombar/bottombar.component';
import {RouterModule} from '@angular/router';
import { NewsletterComponent } from './components/newsletter/newsletter.component';
import {ReactiveFormsModule} from '@angular/forms';
import { TopBarAllComponent } from './components/top-bar-all/top-bar-all.component';

@NgModule({
  declarations: [TopbarComponent, BottombarComponent, NewsletterComponent, TopBarAllComponent],
    imports: [
        CommonModule, RouterModule, ReactiveFormsModule
    ],
  exports: [
    TopbarComponent, BottombarComponent, NewsletterComponent, TopBarAllComponent
  ]
})
export class SharedModule { }
