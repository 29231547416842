import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent implements OnInit {
  show: boolean;
  constructor() { }

  ngOnInit() {
  }

  toggleFunction() {
    const x: HTMLElement  = document.getElementById('header-body');
    if (x.style.height === 'auto') {
      x.style.height = '1000px';
    } else {
      x.style.height = 'auto';
    }
  }
  toggleDropdown() {
    // console.log('clicked');
    this.show = !this.show;
    // const x: HTMLElement  = document.getElementById('drop');
    // if (x.style.display === 'block') {
    //   x.style.height = 'none';
    // } else {
    //   x.style.height = 'block';
    // }
  }

}
