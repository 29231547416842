<app-topbar></app-topbar>
<div role="main" class="main">
  <section class="section section-concept  pt-5 m-0 icon-bagg" id="section-concept" style="animation-duration: 750ms; animation-delay: 300ms; animation-fill-mode: forwards;">
    <div class="container pt-lg-5 mt-5">
      <div class="row align-items-center pt-3">
        <div class="col-lg-5 z-index-2">
          <!--								<h5 class="text-primary font-weight-bold mb-1 appear-animation appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-duration="750">	Welcome to the era of Modern Organic and Eco – Friendly Integrated Agriculture</h5>-->
          <h1 class="font-weight-bold text-8 text-green line-height-2 mb-3 appear-animation appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="400" data-appear-animation-duration="750">
            Welcome to the era of Modern Organic and Eco – Friendly Integrated Agriculture
          </h1>
          <p class="custom-font-size-1 text-dark appear-animation  appear-animation-visible d-sm-none d-lg-block" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="900" data-appear-animation-duration="750">
            Naze Farms is an Integrated Agricultural mega-hub located in the heart of Imo state. Our revolutionary approach to farming is centered around our core values of: Environmental Sustainability, Societal Impact and Continuous Improvement.<br> At Naze Farms we leverage on latest cutting-edge knowledge in the Biological, Energy and Information Sciences to ensure continuous improvement and value addition.
          </p>
          <a href="#" class="btn btn-gradient btn-rounded btn-modern btn-px-5 text-3 appear-animation appear-animation-visible" data-appear-animation="fadeIn" data-appear-animation-delay="300">Learn More</a>

        </div>
        <div class="col-lg-6 offset-lg-1 appear-animation appear-animation-visible" data-appear-animation="fadeIn" data-appear-animation-delay="1200" data-appear-animation-duration="750">
          <img src="assets/img/control/front-farm.png" class="img-fluid appear-animation  appear-animation-visible farm" alt="Screenshot 2" data-appear-animation="fadeInUp" data-appear-animation-delay="600">
        </div>
        <div class="col-md-12 custom-header-bar py-4 pr-5 appear-animation  appear-animation-visible z-index-2" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="100" data-appear-animation-duration="750" style="margin-top: -50px;">
          <div class="row">

            <div class="col-sm-4 col-md-4 col-xl-4 text-center box-shadow ">
              <div class="align-items-center text-center bg-white p-2 py-lg-4 border-radius">
                <i class="fa fa-4x fa-sustain pull-right"></i>
                <span class="text-center text-6 font-weight-bold text-4 text-dark line-height-2 pl-lg-3 mb-0 set">
												Sustainability
											</span>
              </div>
            </div>
            <div class="col-sm-4 col-md-4 col-xl-4 text-center box-shadow ">
              <div class="align-items-center text-center bg-white  p-2 py-lg-4 border-radius">
                <i class="fa fa-4x fa-impact pull-right"></i>
                <span class="text-center text-6 font-weight-bold text-4 text-dark line-height-2 pl-lg-3 mb-0 set">
												Impact
											</span>
              </div>
            </div>
            <div class="col-sm-4 col-md-4 col-xl-4 text-center box-shadow ">
              <div class="align-items-center text-center bg-white p-2 py-lg-4 border-radius">
                <i class="fa fa-4x fa-modern pull-right"></i>
                <span class="text-center text-6 font-weight-bold text-4 text-dark line-height-2 pl-lg-3 mb-0 set">
												Modernization
											</span>
              </div>
            </div>
          </div>
          <p class="custom-font-size-1 text-dark appear-animation  appear-animation-visible  d-lg-none d-sm-block" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="900" data-appear-animation-duration="750">
            Naze Farms is an Integrated Agricultural mega-hub located in the heart of Imo state. Our revolutionary approach to farming is centered around our core values of: Environmental Sustainability, Societal Impact and Continuous Improvement.<br> At Naze Farms we leverage on latest cutting-edge knowledge in the Biological, Energy and Information Sciences to ensure continuous improvement and value addition.
          </p>
        </div>
      </div>
    </div>
  </section>

  <section class="section section-no-border bg-color-light-scale-1 m-0 make-upper" style="background-image: url(assets/img/control/bg-green.svg); background-repeat: no-repeat; background-color: #ffffff !important;">
    <div class="container-fluid upper">
      <div class="bg-white border-all-green m-2 m-lg-5 p-3 p-lg-5">
        <div class="row align-items-center">
          <div class="col-md-6">
            <div class="appear-animation appear-animation-visible" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="0" data-appear-animation-duration="750">
              <div class="strong-shadow rounded strong-shadow-top-left">
                <img src="assets/img/control/guy-smile.png" class="img-fluid box-shadow-3" alt="" />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <h2 class="font-weight-bold text-12 mb-0 appear-animation text-green" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200" data-appear-animation-duration="750">Our Farm<br> Culture and Values</h2>
            <p class="pb-2 mb-4 appear-animation appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="800" data-appear-animation-duration="750">
              Our core values are the driving force behind the establishment of the Farm. We are committed to empowering and steering Nigeria towards a new era of environmentally sustainable and integrated farming, leading to food security in Nigeria. In addition, our innovative approach to agriculture seeks to empower the teeming unemployed youths in Nigeria, through a clear demonstration of the strategic importance of agriculture in the economy.
            </p>
            <!--									<p class="pb-2 mb-4 appear-animation appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="800" data-appear-animation-duration="750">-->
            <!--									</p>-->
            <ul style="list-style-type: none">
              <li>
                <i class="fa fa-check1 check" ></i>
                <b>Environmental Sustainability –</b> Our Integrated Farming Model is at the core of our self-sustaining Agricultural system which helps farmers produce better food in larger quantities with smaller investments thereby ensuring a more viable Agri-business solution. Emphasis is on the renewal and regeneration of the ecosystem for sustainable yields while ensuring reservation of the environment in line with the global climate change agenda.

              </li>
              <li>
                <i class="fa fa-check2 check"></i>
                <b>Societal Impact –</b> Through the “GAIN” initiative, Naze Farms seeks to ensure significant social and economic impact by empowering the youth with requisite technical, leadership and business skills in integrated agriculture. Of great importance is the reorientation of the mind of the youths to see the grossly under estimated tremendous value in agriculture as an end-to-end value chain business.

              </li>
              <li>
                <i class="fa fa-check2 check"></i>
                <b>Continuous Improvement –</b> By combining the very latest trends and best practices in biological, energy and informational sciences, we ensure the constant continuous improvement mindset and practice.
              </li>
            </ul>

          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row counters">
        <div class="col-lg-10 text-center offset-lg-1">
          <div class="d-flex justify-content-center">
            <!--									<h1 class="font-weight-bold text-9 text-center d-inline title text-green mb-2 appear-animation animated appear-animation-visible fadeInUpShorter" data-appear-animation="fadeInUpShorter" data-appear-animation-duration="750" data-plugin-options="{'accY': -200}" style="animation-delay: 100ms;">Production Cluster 1</h1>-->
            <h2 class="font-weight-bold text-14 title text-white mb-3 appear-animation  appear-animation-visible line-height-1" data-appear-animation="fadeInUpShorter" data-appear-animation-duration="750" data-plugin-options="{'accY': -200}">Naze Farms<br/>Development</h2>

          </div>

          <div class="row pb-lg-5 d-none d-lg-flex">
            <div class="d-flex col-sm-6 col-lg-4 mb-4 appear-animation appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200" data-appear-animation-duration="750">
              <div class="bg-color-light rounded p-5 box-shadow border-radius">
                <i class="fa fa-preliminary mt-4"></i>
                <h4 class="text-4 mb-2">Preliminary</h4>
                <p class="custom-text-color-1 text-3 color-inherit mb-0">
                  Land Clearing, Plots demarcation, Road network opening, Bore hole, Water tower, Water distribution, Electrical distribution, Power house, Equipment
                </p>
              </div>
            </div>
            <div class="d-flex col-sm-6 col-lg-4 mb-4 appear-animation appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="400" data-appear-animation-duration="750">
              <div class="bg-color-light rounded p-5 box-shadow border-radius">
                <i class="fa fa-market mt-4"></i>
                <h4 class="text-4 mb-2">Market Garden</h4>
                <p class="custom-text-color-1 text-3 color-inherit mb-0">Bed Construction, Grass mulch, Plastic Mulch, Irrigation Material, Working Tools, Seeds & Products.</p>
              </div>
            </div>
            <div class="d-flex col-sm-6 col-lg-4 mb-4 appear-animation appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="600" data-appear-animation-duration="750">
              <div class="bg-color-light rounded p-5 box-shadow border-radius">
                <i class="fa fa-agroforestry mt-4"></i>
                <h4 class="text-4 mb-2">Agroforestry</h4>
                <p class="custom-text-color-1 text-3 color-inherit mb-0">
                  Pawpaw plantation, casual worker
                </p>
              </div>
            </div>
            <div class="d-flex col-sm-6 col-lg-4 mb-4 appear-animation appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="800" data-appear-animation-duration="750">
              <div class="bg-color-light rounded p-5 box-shadow border-radius">
                <i class="fa fa-broilers mt-4"></i>
                <h4 class="text-4 mb-2">Broilers Production</h4>
                <p class="custom-text-color-1 text-3 color-inherit mb-0">Brooding House, Pilotis, Equipment, Technician Staff, Casual Workers
                </p>
              </div>
            </div>
            <div class="d-flex col-sm-6 col-lg-4 mb-4 appear-animation appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200" data-appear-animation-duration="750">
              <div class="bg-color-light rounded p-5 box-shadow border-radius">
                <i class="fa fa-abbatoir mt-4"></i>
                <h4 class="text-4 mb-2">Abattoir & Smoking Kiln</h4>
                <p class="custom-text-color-1 text-3 color-inherit mb-0">
                  Small Equipment, Technician Staff, casual Workers
                </p>
              </div>
            </div>
            <div class="d-flex col-sm-6 col-lg-4 mb-4 appear-animation appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="400" data-appear-animation-duration="750">
              <div class="bg-color-light rounded p-5 box-shadow border-radius">
                <i class="fa fa-general mt-4"></i>
                <h4 class="text-4 mb-2">General Service</h4>
                <p class="custom-text-color-1 text-3 color-inherit mb-0">
                  Security, Admin & Sales, Senior Staff Residence, Technician Staff, Casual Workers
                </p>
              </div>
            </div>

          </div>
          <div class="row d-lg-none d-sm-block">
            <div class="owl-carousel owl-theme mb-0" data-plugin-options="{'items': 1, 'autoHeight': true}">
              <div>
                <div class="mb-4 mx-2 appear-animation appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200" data-appear-animation-duration="750">
                  <div class="bg-color-light rounded p-5 box-shadow border-radius">
                    <i class="fa fa-preliminary mt-4"></i>
                    <h4 class="text-4 text-green mb-2">Preliminary</h4>
                    <p class="custom-text-color-1 text-3 color-inherit mb-0">
                      Land Clearing, Plots demarcation, Road network opening, Bore hole, Water tower, Water distribution, Electrical distribution, Power house, Equipment
                    </p>
                  </div>
                </div>
              </div>
              <div class="mb-4 mx-2 appear-animation appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="400" data-appear-animation-duration="750">
                <div class="bg-color-light rounded p-5 box-shadow border-radius">
                  <i class="fa fa-market mt-4"></i>
                  <h4 class="text-4 text-green mb-2">Market Garden</h4>
                  <p class="custom-text-color-1 text-3 color-inherit mb-0">Bed Construction, Grass mulch, Plastic Mulch, Irrigation Material, Working Tools, Seeds & Products.</p>
                </div>
              </div>
              <div class="mb-4 mx-2 appear-animation appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="600" data-appear-animation-duration="750">
                <div class="bg-color-light rounded p-5 box-shadow border-radius">
                  <i class="fa fa-agroforestry mt-4"></i>
                  <h4 class="text-4 text-green mb-2">Agroforestry</h4>
                  <p class="custom-text-color-1 text-3 color-inherit mb-0">
                    Pawpaw plantation, casual worker
                  </p>
                </div>
              </div>
              <div class="mb-4 mx-2 appear-animation appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="800" data-appear-animation-duration="750">
                <div class="bg-color-light rounded p-5 box-shadow border-radius">
                  <i class="fa fa-broilers mt-4"></i>
                  <h4 class="text-4 text-green mb-2">Broilers Production</h4>
                  <p class="custom-text-color-1 text-3 color-inherit mb-0">Brooding House, Pilotis, Equipment, Technician Staff, Casual Workers
                  </p>
                </div>
              </div>
              <div class="mb-4 mx-2 appear-animation appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200" data-appear-animation-duration="750">
                <div class="bg-color-light rounded p-5 box-shadow border-radius">
                  <i class="fa fa-abbatoir mt-4"></i>
                  <h4 class="text-4 text-green mb-2">Abattoir & Smoking Kiln</h4>
                  <p class="custom-text-color-1 text-3 color-inherit mb-0">
                    Small Equipment, Technician Staff, casual Workers
                  </p>
                </div>
              </div>
              <div class="mb-4 mx-2 appear-animation appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="400" data-appear-animation-duration="750">
                <div class="bg-color-light rounded p-5 box-shadow border-radius">
                  <i class="fa fa-general mt-4"></i>
                  <h4 class="text-4 text-green mb-2">General Service</h4>
                  <p class="custom-text-color-1 text-3 color-inherit mb-0">
                    Security, Admin & Sales, Senior Staff Residence, Technician Staff, Casual Workers
                  </p>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section section-no-border bg-color-light-scale-1 m-0 mt-2 p-0">
    <a href="#" title="Visit Our Organic Ecommerce Shop">
      <img src="assets/img/control/wide-bg.png" class="img-fluid appear-animation appear-animation-visible" alt="Screenshot 2" data-appear-animation="fadeInUp" data-appear-animation-delay="600">
    </a>
  </section>

</div>
<app-bottombar></app-bottombar>
