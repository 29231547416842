<app-top-bar-all></app-top-bar-all>
<div role="main" class="main">
  <section class="section section-concept section-no-border section-light pt-5 m-0 program-bold" id="" style=" background-color:#439A35 !important">
    <div class="container">
      <div class="row align-items-center pt-5">
        <div class="col-lg-7 col-sm-12 mb-5 caption">
          <h1 class="font-weight-bold text-12 line-height-2 text-white mb-3 mt-5 appear-animation text-white animated appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="400" data-appear-animation-duration="750">
            Gallery
          </h1>
          <p class="custom-font-size-1 appear-animation text-yellow text-4 animated appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="900" data-appear-animation-duration="750">
									<span>
										<a href="#" class="text-yellow">Home</a>
									</span>
            <span class="ml-5">
										<a href="#" class="text-yellow">Gallery</a>
									</span>
          </p>
        </div>

      </div>

    </div>

  </section>
  <section class="section sect section-concept sect section-no-border section-white  pt-5 m-0" id="section-concept">
    <div class="container">
      <div class="col-sm-12 col-lg-12 counter mb-lg-5 mb-md-0 about">
        <div class="row portfolio-list sort-destination" data-sort-id="portfolio">

          <div class="col-sm-6 col-lg-4 isotope-item brands">
            <div class="portfolio-item">
              <a href="#">
										<span class="thumb-info thumb-info-lighten border-radius-0">
											<span class="thumb-info-wrapper border-radius-0">
												<img src="assets/img/control/gal-1.png" class="img-fluid border-radius-0" alt="">

												<span class="thumb-info-title">
													<span class="thumb-info-inner"><i class="fa fa-expand"></i></span>
												</span>
											</span>
										</span>
              </a>
            </div>
          </div>
          <div class="col-sm-6 col-lg-4 isotope-item brands">
            <div class="portfolio-item">
              <a href="#">
										<span class="thumb-info thumb-info-lighten border-radius-0">
											<span class="thumb-info-wrapper border-radius-0">
												<img src="assets/img/control/gal-2.png" class="img-fluid border-radius-0" alt="">

												<span class="thumb-info-title">
													<span class="thumb-info-inner"><i class="fa fa-expand"></i></span>
												</span>
											</span>
										</span>
              </a>
            </div>
          </div>
          <div class="col-sm-6 col-lg-4 isotope-item brands">
            <div class="portfolio-item">
              <a href="#">
										<span class="thumb-info thumb-info-lighten border-radius-0">
											<span class="thumb-info-wrapper border-radius-0">
												<img src="assets/img/control/gal-3.png" class="img-fluid border-radius-0" alt="">

												<span class="thumb-info-title">
													<span class="thumb-info-inner"><i class="fa fa-expand"></i></span>
												</span>
											</span>
										</span>
              </a>
            </div>
          </div>
          <div class="col-sm-6 col-lg-4 isotope-item brands">
            <div class="portfolio-item">
              <a href="#">
										<span class="thumb-info thumb-info-lighten border-radius-0">
											<span class="thumb-info-wrapper border-radius-0">
												<img src="assets/img/control/gal-4.png" class="img-fluid border-radius-0" alt="">

												<span class="thumb-info-title">
													<span class="thumb-info-inner"><i class="fa fa-expand"></i></span>
												</span>
											</span>
										</span>
              </a>
            </div>
          </div>
          <div class="col-sm-6 col-lg-4 isotope-item brands">
            <div class="portfolio-item">
              <a href="#">
										<span class="thumb-info thumb-info-lighten border-radius-0">
											<span class="thumb-info-wrapper border-radius-0">
												<img src="assets/img/control/gal-5.png" class="img-fluid border-radius-0" alt="">

												<span class="thumb-info-title">
													<span class="thumb-info-inner"><i class="fa fa-expand"></i></span>
												</span>
											</span>
										</span>
              </a>
            </div>
          </div>
          <div class="col-sm-6 col-lg-4 isotope-item brands">
            <div class="portfolio-item">
              <a href="#">
										<span class="thumb-info thumb-info-lighten border-radius-0">
											<span class="thumb-info-wrapper border-radius-0">
												<img src="assets/img/control/gal-6.png" class="img-fluid border-radius-0" alt="">

												<span class="thumb-info-title">
													<span class="thumb-info-inner"><i class="fa fa-expand"></i></span>
												</span>
											</span>
										</span>
              </a>
            </div>
          </div>
          <div class="col-sm-6 col-lg-4 isotope-item brands">
            <div class="portfolio-item">
              <a href="#">
										<span class="thumb-info thumb-info-lighten border-radius-0">
											<span class="thumb-info-wrapper border-radius-0">
												<img src="assets/img/control/gal-7.png" class="img-fluid border-radius-0" alt="">

												<span class="thumb-info-title">
													<span class="thumb-info-inner"><i class="fa fa-expand"></i></span>
												</span>
											</span>
										</span>
              </a>
            </div>
          </div>
          <div class="col-sm-6 col-lg-4 isotope-item brands">
            <div class="portfolio-item">
              <a href="#">
										<span class="thumb-info thumb-info-lighten border-radius-0">
											<span class="thumb-info-wrapper border-radius-0">
												<img src="assets/img/control/gal-8.png" class="img-fluid border-radius-0" alt="">

												<span class="thumb-info-title">
													<span class="thumb-info-inner"><i class="fa fa-expand"></i></span>
												</span>
											</span>
										</span>
              </a>
            </div>
          </div>
          <div class="col-sm-6 col-lg-4 isotope-item brands">
            <div class="portfolio-item">
              <a href="#">
										<span class="thumb-info thumb-info-lighten border-radius-0">
											<span class="thumb-info-wrapper border-radius-0">
												<img src="assets/img/control/gal-9.png" class="img-fluid border-radius-0" alt="">

												<span class="thumb-info-title">
													<span class="thumb-info-inner"><i class="fa fa-expand"></i></span>
												</span>
											</span>
										</span>
              </a>
            </div>
          </div>

        </div>

      </div>
    </div>
  </section>

</div>
<app-bottombar></app-bottombar>
