<app-top-bar-all></app-top-bar-all>
<div role="main" class="main">
  <section class="section section-concept section-no-border section-light pt-5 m-0 program-bold" id="" style=" background-color:#439A35 !important">
    <div class="container">
      <div class="row align-items-center pt-5">
        <div class="col-lg-12 col-sm-12 mb-5 caption">
          <h1 class="font-weight-bold text-12 line-height-2 mb-3 mt-5 appear-animation text-white animated appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="400" data-appear-animation-duration="750">
            About
          </h1>
          <p class="custom-font-size-1 appear-animation text-white text-4 animated appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="900" data-appear-animation-duration="750" style="color: #fff !important;">
									<span>
										<a href="#">Home</a>
									</span>
            <span class="ml-5">
										<a href="#">About</a>
									</span>
          </p>
        </div>
      </div>
    </div>

  </section>
  <section class="section sect section-concept sect section-no-border section-white  pt-5 m-0" id="section-concept1">
    <div class="container">
      <div class="row align-items-center counters">
        <div class="col-sm-12 col-lg-4 counter mb-md-0 ">
          <img src="assets/img/control/our-story.png" class="img-fluid  appear-animation animated fadeInUp appear-animation-visible" alt="Screenshot 2" data-appear-animation="fadeInUp" data-appear-animation-delay="600" style="animation-delay: 600ms;">
        </div>
        <div class="col-sm-12 col-lg-8 counter mb-lg-5 mb-md-0 about">
          <h1 class="font-weight-bold text-9 text-left text-green mb-2 appear-animation animated appear-animation-visible fadeInUpShorter" data-appear-animation="fadeInUpShorter" data-appear-animation-duration="750" data-plugin-options="{'accY': -200}" style="animation-delay: 100ms;">About</h1>

          <div class="appear-animation text-left animated fadeInLeftShorter appear-animation-visible" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="750" data-appear-animation-duration="750" style="animation-delay: 750ms;">
            <p class="text-1rem text-color-default negative-ls-05 pt-3 appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="500" data-appear-animation-duration="750" style="animation-delay: 500ms;">
              Naze farms started as a movement to encourage the participation of youths in Agri-business through innovative farming technology and practices, ensuring environmental sustainability and consistent economic growth in the Nigerian Agricultural sector.
              <br><br>
              Naze Farms is currently at the forefront of the GLAMORIZING AGRIBUSINESS IN NIGERIA campaign also known as “GAIN”, which seeks to introduce a new more innovative approach to Agriculture.
              <br><br>
              GAIN was initiated to drive involvement by encouraging organized private sector and development agencies to invest in the development of the industry. In addition to this, GAIN provides information on Agri-business education/workshops and investment opportunities. The goal of Naze Farms is to drive food security, reduce food imports, promote exports and create job opportunities throughout Nigeria.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section sect section-concept sect section-no-border section-white  pt-5 m-0" id="section-concept2">


    <div class="container">
      <div class="row align-items-center counters">
        <div class="col-sm-12 col-lg-6 counter mb-md-0 ">
          <img src="assets/img/control/beside-naze-green.png" class="img-fluid imgsvg appear-animation animated fadeInUp appear-animation-visible" alt="Screenshot 2" data-appear-animation="fadeInUp" data-appear-animation-delay="600" style="animation-delay: 600ms;">
        </div>
        <div class="col-sm-12 col-lg-6 counter mb-lg-5 mb-md-0 about">
          <h1 class="font-weight-bold text-9 text-left text-green mb-2 appear-animation animated appear-animation-visible fadeInUpShorter" data-appear-animation="fadeInUpShorter" data-appear-animation-duration="750" data-plugin-options="{'accY': -200}" style="animation-delay: 100ms;">
            Naze Green<br>
            Development Hub
          </h1>

          <!--								<h4 class="font-weight-bold text-6 text-left mb-0 appear-animation animated appear-animation-visible how-title" data-appear-animation="fadeInUpShorter" data-appear-animation-duration="750" data-plugin-options="{'accY': -200}"></h4>-->

          <div class="appear-animation text-left animated fadeInLeftShorter appear-animation-visible" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="750" data-appear-animation-duration="750" style="animation-delay: 750ms;">
            <p class="text-1rem text-color-default negative-ls-05 pt-3 appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="500" data-appear-animation-duration="750" style="animation-delay: 500ms;">
              Naze Farms decided to set up an eco-friendly infrastructure platform to further the GAIN initiative. To achieve this, Naze has partnered with Songhai Farms from Benin Republic, to create a sustainable development hub that will serve as the gateway for the incubation of young entrepreneurs. The focus will be in three categories: Learning/Development center, Crop/Livestock/Animal Husbandry, Eco-tourism.
            </p>
            <p>
              The focus will be in three categories
              <br>Learning/Development centre
              <br>Crop/Livestock/Animal Husbandry
              <br>Eco-tourism
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section sect section-concept sect section-no-border section-white  pt-5 m-0" id="section-concept3">
    <div class="container">
      <div class="row align-items-center counters">
        <div class="col-sm-12 col-lg-6 counter mb-lg-5 mb-md-0 about">
          <h1 class="font-weight-bold text-9 text-left text-green mb-2 appear-animation animated appear-animation-visible fadeInUpShorter" data-appear-animation="fadeInUpShorter" data-appear-animation-duration="750" data-plugin-options="{'accY': -200}" style="animation-delay: 100ms;">
            Learning & Development<br>Centre
          </h1>

          <div class="appear-animation text-left animated fadeInLeftShorter appear-animation-visible" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="750" data-appear-animation-duration="750" style="animation-delay: 750ms;">
            <p class="text-1rem text-color-default negative-ls-05 pt-3 appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="500" data-appear-animation-duration="750" style="animation-delay: 500ms;">
              The Learning & Development Centre will provide coaching and support services through the provision of requisite skills, knowledge and technical know how for a new generation of youth leaders in integrated agriculture business in Nigeria.
            </p>
          </div>
        </div>
        <div class="col-sm-12 col-lg-6 counter mb-md-0 ">
          <img src="assets/img/control/learning.png" class="img-fluid imgsvg appear-animation animated fadeInUp appear-animation-visible" alt="Screenshot 2" data-appear-animation="fadeInUp" data-appear-animation-delay="600" style="animation-delay: 600ms;">
        </div>
      </div>
    </div>
  </section>

  <section class="section sect section-concept sect section-no-border section-white  pt-5 m-0" id="section-concept4">
    <div class="container">
      <div class="row align-items-center counters">
        <div class="col-sm-12 col-lg-6 counter mb-md-0 ">
          <img src="assets/img/control/agriculture.png" class="img-fluid imgsvg appear-animation animated fadeInUp appear-animation-visible" alt="Screenshot 2" data-appear-animation="fadeInUp" data-appear-animation-delay="600" style="animation-delay: 600ms;">
        </div>
        <div class="col-sm-12 col-lg-6 counter mb-lg-5 mb-md-0 about">
          <h1 class="font-weight-bold text-9 text-left text-green mb-2 appear-animation animated appear-animation-visible fadeInUpShorter" data-appear-animation="fadeInUpShorter" data-appear-animation-duration="750" data-plugin-options="{'accY': -200}" style="animation-delay: 100ms;">
            Agriculture
          </h1>

          <div class="appear-animation text-left animated fadeInLeftShorter appear-animation-visible" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="750" data-appear-animation-duration="750" style="animation-delay: 750ms;">
            <p class="text-1rem text-color-default negative-ls-05 pt-3 appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="500" data-appear-animation-duration="750" style="animation-delay: 500ms;">
              Emphasis will be on commercially viable high value crops, livestock and fish products that can easily be sold at the local markets in Owerri, Imo State, South East and rest of Nigeria.
              <br><br>
              Plans for the immediate future include the production of key value chain products like animal feed, as well as the utilization of the waste generated for renewable energy within the farm.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section sect section-concept sect section-no-border section-white  pt-5 m-0" id="section-concept5">
    <div class="container">
      <div class="row align-items-center counters">
        <div class="col-sm-12 col-lg-6 counter mb-lg-5 mb-md-0 about">
          <h1 class="font-weight-bold text-9 text-left text-green mb-2 appear-animation animated appear-animation-visible fadeInUpShorter" data-appear-animation="fadeInUpShorter" data-appear-animation-duration="750" data-plugin-options="{'accY': -200}" style="animation-delay: 100ms;">
            Eco Tourism
          </h1>
          <div class="appear-animation text-left animated fadeInLeftShorter appear-animation-visible" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="750" data-appear-animation-duration="750" style="animation-delay: 750ms;">
            <p class="text-1rem text-color-default negative-ls-05 pt-3 appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="500" data-appear-animation-duration="750" style="animation-delay: 500ms;">
              The plan is to develop the farm to a model centre where visitors and general enthusiasts of agriculture will visit with arranged tours and lectures, to showcase the integrated organic farming model in action.
            </p>
          </div>
        </div>
        <div class="col-sm-12 col-lg-6 counter mb-md-0 ">
          <img src="assets/img/control/eco-tourism.png" class="img-fluid imgsvg appear-animation animated fadeInUp appear-animation-visible" alt="Screenshot 2" data-appear-animation="fadeInUp" data-appear-animation-delay="600" style="animation-delay: 600ms;">
        </div>
      </div>
    </div>
  </section>

<!--  <section class="section sect section-concept sect section-no-border section-white  pt-5 m-0" id="section-concept">-->
<!--    <div class="container">-->
<!--      <div class="row align-items-center">-->
<!--        <div class="col-sm-12 col-lg-8 counter mb-md-0 about pr-0">-->
<!--          <img src="assets/img/control/map.png" class="img-fluid appear-animation animated fadeInUp appear-animation-visible" alt="Screenshot 2" data-appear-animation="fadeInUp" data-appear-animation-delay="600" style="animation-delay: 600ms;">-->
<!--        </div>-->
<!--        <div class="col-sm-12 col-lg-4 counter plan mb-md-0 bg-white p-3 box-shadow-1-dark" style="box-shadow: 0px 0px 20px #00000029;">-->
<!--          <h1 class="font-weight-bold text-6 text-left text-green mb-2 appear-animation animated appear-animation-visible fadeInUpShorter" data-appear-animation="fadeInUpShorter" data-appear-animation-duration="750" data-plugin-options="{'accY': -200}" style="animation-delay: 100ms;">-->
<!--            Plan Layout - Infrastructure-->
<!--          </h1>-->
<!--          <div class="appear-animation text-left animated fadeInLeftShorter appear-animation-visible" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="750" data-appear-animation-duration="750" style="animation-delay: 750ms;">-->

<!--            <ul>-->
<!--              <li>Residence</li>-->
<!--              <li>Sales Point</li>-->
<!--              <li>Office</li>-->
<!--              <li>Mini-Fish Pond</li>-->
<!--              <li>Market Garden</li>-->
<!--              <li>Pilotis</li>-->
<!--              <li>Bakery</li>-->
<!--              <li>Broilers Pen</li>-->
<!--              <li>Goat and Sheep Ranch</li>-->
<!--              <li>Feed Mill</li>-->
<!--              <li>Abattoir</li>-->
<!--              <li>Juice Processing Area</li>-->
<!--              <li>Earthen Pond</li>-->
<!--              <li>Overhead tank and industrial borehole</li>-->
<!--              <li>Power House</li>-->
<!--            </ul>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </section>-->


  <section class="section section-no-border section-white pb-3 m-0">
    <div class="container text-center">
      <h1 class="font-weight-bold text-10 text-center text-green mb-2 appear-animation animated appear-animation-visible fadeInUpShorter" data-appear-animation="fadeInUpShorter" data-appear-animation-duration="750" data-plugin-options="{'accY': -200}" style="animation-delay: 100ms;">
        Want to find out more about us?<br> see some frequently asked questions
      </h1>
    </div>
  </section>

</div>
<app-bottombar></app-bottombar>
