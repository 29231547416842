import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from '../home/home.component';
import {AboutComponent} from '../about/about.component';
import {ServicesComponent} from '../services/services.component';
import {GalleryComponent} from '../gallery/gallery.component';
import {ConnectComponent} from '../connect/connect.component';

const routes: Routes = [
  {path: '', component: HomeComponent,
    runGuardsAndResolvers: 'always'},
  {path: 'about', component: AboutComponent,
    runGuardsAndResolvers: 'always'},
  {path: 'services', component: ServicesComponent,
    runGuardsAndResolvers: 'always'},
  {path: 'gallery', component: GalleryComponent,
    runGuardsAndResolvers: 'always'},
  {path: 'contact', component: ConnectComponent,
    runGuardsAndResolvers: 'always'}
  // {path: 'terms-of-use', component: TermsOfUseComponent,
  //   runGuardsAndResolvers: 'always'},
  // {path: 'privacy-policy', component: PrivacyPolicyComponent,
  //   runGuardsAndResolvers: 'always'}
  ];


@NgModule({
  imports: [
    // RouterModule.forRoot(routes, {enableTracing: true, onSameUrlNavigation: 'reload'}), CommonModule
    RouterModule.forRoot(routes),
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})

export class RoutingModule { }

