import { Component, OnInit } from '@angular/core';
import {SubscribeService} from '../../services/subscribe.service';

@Component({
  selector: 'app-bottombar',
  templateUrl: './bottombar.component.html',
  styleUrls: ['./bottombar.component.css']
})
export class BottombarComponent implements OnInit {
  form;
  clicked;
  status;
  response;
  data;
  constructor(private subscribeService: SubscribeService) { }

  ngOnInit(): void {
  }
  subscribe(): any {
    this.clicked = true;
    // console.log(this.form.value);
    this.subscribeService.subscribed(this.form.value).subscribe(
      data => {
        this.data = data;
        this.status = this.data.status;
        this.response = this.data.message;
        this.clicked = false;
      },
      (error: Response) => {
        console.log(error);
        this.clicked = false;
      }
    );
  }

  get email(): any {
    return this.form.get('email');
  }

}
