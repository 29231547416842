<app-top-bar-all></app-top-bar-all>
<div role="main" class="main">
  <section class="section section-concept section-no-border section-light pt-5 m-0 program-bold" id="" style=" background-color:#439A35 !important">
    <div class="container">
      <div class="row align-items-center pt-5">
        <div class="col-lg-7 col-sm-12 mb-5 caption">
          <h1 class="font-weight-bold text-12 line-height-2 mb-3 mt-5 appear-animation text-white animated appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="400" data-appear-animation-duration="750">
            Contact us
          </h1>
          <p class="custom-font-size-1 appear-animation text-yellow text-4 animated appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="900" data-appear-animation-duration="750">
									<span>
										<a href="#" class="text-yellow">Home</a>
									</span>
            <span class="ml-5">
										<a href="#" class="text-yellow">Contact us</a>
									</span>
          </p>
        </div>

      </div>

    </div>

  </section>
  <section class="section sect section-concept sect section-no-border section-white  pt-5 m-0" id="section-concept">
    <div class="container">
      <div class="col-sm-12 col-lg-12 counter mb-lg-5 mb-md-0 about">
        <div class="row portfolio-list sort-destination" data-sort-id="portfolio">


        </div>
        <div class="row py-4">
          <div class="col-lg-6 offset-lg-3">

            <div class="overflow-hidden mb-1">
              <h2 class="font-weight-normal text-green text-7 mt-2 mb-0 appear-animation appear-animation-visible" data-appear-animation="maskUp" data-appear-animation-delay="200"><strong class="font-weight-extra-bold">Contact</strong> Us</h2>
            </div>
            <div class="overflow-hidden mb-4 pb-3">
              <p class="mb-0 appear-animation appear-animation-visible" data-appear-animation="maskUp" data-appear-animation-delay="400">Feel free to ask for details, don't save any questions!</p>
            </div>

            <p class="alert" [class.alert-danger]="!status" [class.alert-success]="status" *ngIf="response">{{response}}</p>

            <form id="contactForm"  [formGroup]="formed" (ngSubmit)="connect(formed)" class="contact-form rounded">


              <div class="form-row">
                <div class="form-group col-lg-6">
                  <input type="text" value="" formControlName="name" placeholder="Your Name" data-msg-required="Please enter your name." maxlength="100" class="form-control round" name="name" id="name" required="">
                  <div *ngIf="name.touched && name.invalid" class="alert alert-danger error">
                    <div *ngIf="name.errors.required">Please enter your name.</div>
                    <div *ngIf="name.errors.minlength">Name should be minimum of 3 characters</div>
                  </div>
                </div>
                <div class="form-group col-lg-6">
                  <input type="email" formControlName="email" value="" placeholder="Your Email" data-msg-required="Please enter your email address." data-msg-email="Please enter a valid email address." maxlength="100" class="form-control round" name="email" id="email" required="">
                  <div *ngIf="email.invalid && (email.touched || email.dirty)" class="alert alert-danger error">
                    <div *ngIf="email.errors.required">Email is required</div>
                    <div *ngIf="email.errors.cannotContainSpace">Email cannot contain space</div>
                  </div>
              </div>
              </div>
              <div class="form-row">
                <div class="form-group col">
<!--                  <textarea maxlength="5000" placeholder="Message" rows="8" class="form-control round" name="message" id="message" required></textarea>-->
                  <textarea maxlength="5000" placeholder="Message" data-msg-required="Please enter your message." rows="8" class="form-control round" name="message"  formControlName="message" id="message" required=""></textarea>
                  <div *ngIf="message.touched && message.invalid" class="alert alert-danger error">
                    <div *ngIf="message.errors.required">Message is required</div>
                    <div *ngIf="message.errors.minlength">Message should be minimum of 3 characters</div>
                  </div>
                </div>
              </div>
              <!-- <div class="form-row">
                <div class="form-group col">
                  <input  type="submit" value='Submit Now' class="btn btn-gradient btn-modern round" data-loading-text="Loading...">
                </div>
              </div> -->
                <div class="form-row">
    <div class="form-group col">
      <button type="submit" [disabled]="loading" class="btn btn-gradient btn-modern round">
        <span *ngIf="!loading">Submit Now</span>
        <span *ngIf="loading" class="loading-icon"></span>
      </button>
    </div>
  </div>

            </form>

          </div>
        </div>


      </div>
    </div>
  </section>
  <section class="section section-no-border bg-color-light-scale-1 m-0 p-0">
    <div class="container">
      <div class="row">
        <div class="col-lg-10 offset-lg-1">
          <a href="#" title="Visit Our Organic Ecommerce Shop">
            <img src="assets/img/control/contact-banner.png" class="img-fluid d-none d-md-block appear-animation appear-animation-visible" alt="Screenshot 2" data-appear-animation="fadeInUp" data-appear-animation-delay="600">
          </a>
        </div>
      </div>
    </div>
  </section>

</div>
<app-bottombar></app-bottombar>
