<app-top-bar-all></app-top-bar-all>
<div role="main" class="main">
  <section class="section section-concept section-no-border section-light pt-5 m-0 program-bold" id="" style=" background-color:#439A35 !important">
    <div class="container">
      <div class="row align-items-center pt-5">
        <div class="col-lg-7 col-sm-12 mb-5 caption">
          <h1 class="font-weight-bold text-12 line-height-2 text-green mb-3 mt-5 appear-animation text-white animated appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="400" data-appear-animation-duration="750">
            Services
          </h1>
          <p class="custom-font-size-1 appear-animation text-yellow text-4 animated appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="900" data-appear-animation-duration="750">
									<span>
										<a href="#" class="text-yellow">Home</a>
									</span>
            <span class="ml-5">
										<a href="#" class="text-yellow">Services</a>
									</span>
          </p>
        </div>

      </div>

    </div>

  </section>
  <section class="section sect section-concept sect section-no-border section-white  pt-5 m-0" id="section-concept">
    <div class="container">
      <div class="row pb-5">
        <div class="col-lg-10 offset-lg-1 text-center">
          <div class="row pb-5">
            <div class="d-flex col-sm-6 col-lg-4 mb-4 appear-animation appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="600" data-appear-animation-duration="750">
              <div class="bg-color-light rounded p-5 box-shadow border-radius">
                <i class="fa fa-abbatoir mt-4"></i>
                <h4 class="text-4 mb-2">Primary Production</h4>
                <p class="custom-text-color-1 text-3 color-inherit mb-0">
                  Broilers with 2,000 birds every week <br>Goat Ranch<br>Vegetables and fruits<br>Fish Production
                </p>
              </div>
            </div>
            <div class="d-flex col-sm-6 col-lg-4 mb-4 appear-animation appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="800" data-appear-animation-duration="750">
              <div class="bg-color-light rounded p-5 box-shadow border-radius">
                <i class="fa fa-secondary mt-4"></i>
                <h4 class="text-4 mb-2">Secondary Production (Agro-industry)</h4>
                <p class="custom-text-color-1 text-3 color-inherit mb-0">
                  Fruit Juices with 1,000 liters daily capacity<br> Bakery with 100kg flour for bread daily <br>Abattoir for meat products like sausages, pate, smoked meat and fish.<br>Animal feeds
                </p>
              </div>
            </div>
            <div class="d-flex col-sm-6 col-lg-4 mb-4 appear-animation appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200" data-appear-animation-duration="750">
              <div class="bg-color-light rounded p-5 box-shadow border-radius">
                <i class="fa fa-general mt-4"></i>
                <h4 class="text-4 mb-2">Tertiary Production</h4>
                <p class="custom-text-color-1 text-3 color-inherit mb-0">
                  Supermarket, Cold storage and Delivery system, Hospital, Eco-tourism and Agro Services
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    <div class="container">-->
<!--      <div class="row pb-5">-->
<!--        <div class="col-lg-10 offset-lg-1 text-center">-->
<!--          <div class="row pb-5">-->
<!--            <div class="d-flex col-sm-6 col-lg-4 mb-4 appear-animation appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="600" data-appear-animation-duration="750">-->
<!--              <div class="bg-color-light rounded p-5 box-shadow border-radius">-->
<!--                <i class="fa fa-primary mt-4"></i>-->
<!--                <h4 class="text-4 mb-2">Primary Production</h4>-->
<!--                <p class="custom-text-color-1 text-3 color-inherit mb-0">-->
<!--                  Vegetables and fruits Fish Production-->
<!--                </p>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="d-flex col-sm-6 col-lg-4 mb-4 appear-animation appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="800" data-appear-animation-duration="750">-->
<!--              <div class="bg-green text-white rounded p-5 box-shadow border-radius">-->
<!--                <i class="fa fa-agro-industry mt-4"></i>-->
<!--                <h4 class="text-4 text-white mb-2">Secondary Production Agro-industry</h4>-->
<!--                <p class="text-white text-3 color-inherit mb-0">-->
<!--                  Animal feeds-->
<!--                </p>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="d-flex col-sm-6 col-lg-4 mb-4 appear-animation appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200" data-appear-animation-duration="750">-->
<!--              <div class="bg-color-light rounded p-5 box-shadow border-radius">-->
<!--                <i class="fa fa-agro-services mt-4"></i>-->
<!--                <h4 class="text-4 mb-2">Tertiary Production</h4>-->
<!--                <p class="custom-text-color-1 text-3 color-inherit mb-0">-->
<!--                  Agro Services/ Tertiary Production-->
<!--                </p>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </section>
  <section class="section sect section-concept sect section-no-border section-white  pt-5 m-0" id="section-concept2">
    <div class="container">
      <div class="d-flex justify-content-center">
        <h1 class="font-weight-bold text-12 text-center d-inline title text-green mb-lg-5 appear-animation animated appear-animation-visible fadeInUpShorter" data-appear-animation="fadeInUpShorter" data-appear-animation-duration="750" data-plugin-options="{'accY': -200}" style="animation-delay: 100ms;">Some of Our Products</h1>
      </div>
      <div class="col-sm-12 col-lg-12 counter mb-lg-5 mb-md-0 about">
        <div class="row portfolio-list sort-destination" data-sort-id="portfolio">

          <div class="col-sm-6 col-lg-3 isotope-item brands">
            <div class="portfolio-item">
              <a href="#">
										<span class="thumb-info thumb-info-lighten border-radius-0">
											<span class="thumb-info-wrapper border-radius-0">
												<img src="assets/img/control/pawpaw.png" class="img-fluid border-radius-0" alt="">

												<span class="thumb-info-title">
													<span class="thumb-info-inner">Pawpaw</span>
												</span>
											</span>
										</span>
              </a>
            </div>
          </div>
          <div class="col-sm-6 col-lg-3 isotope-item brands">
            <div class="portfolio-item">
              <a href="#">
										<span class="thumb-info thumb-info-lighten border-radius-0">
											<span class="thumb-info-wrapper border-radius-0">
												<img src="assets/img/control/corn.png" class="img-fluid border-radius-0" alt="">

												<span class="thumb-info-title">
													<span class="thumb-info-inner">Maize</span>
												</span>
											</span>
										</span>
              </a>
            </div>
          </div>
          <div class="col-sm-6 col-lg-3 isotope-item brands">
            <div class="portfolio-item">
              <a href="#">
                <span class="thumb-info thumb-info-lighten border-radius-0">
                  <span class="thumb-info-wrapper border-radius-0">
                    <img src="assets/img/control/tomatoes.png" class="img-fluid border-radius-0" alt="">

                    <span class="thumb-info-title">
                      <span class="thumb-info-inner">Tomatoes</span>
                    </span>
                  </span>
                </span>
              </a>
            </div>
          </div>
          <div class="col-sm-6 col-lg-3 isotope-item brands">
            <div class="portfolio-item">
              <a href="#">
                <span class="thumb-info thumb-info-lighten border-radius-0">
                  <span class="thumb-info-wrapper border-radius-0">
                    <img src="assets/img/control/okra.png" class="img-fluid border-radius-0" alt="">
                    <span class="thumb-info-title">
                      <span class="thumb-info-inner">Okra</span>
                    </span>
                  </span>
                </span>
              </a>
            </div>
          </div>
          <div class="col-sm-6 col-lg-3 isotope-item brands">
            <div class="portfolio-item">
              <a href="#">
                  <span class="thumb-info thumb-info-lighten border-radius-0">
                    <span class="thumb-info-wrapper border-radius-0">
                      <img src="assets/img/control/hens.png" class="img-fluid border-radius-0" alt="">
                      <span class="thumb-info-title">
                        <span class="thumb-info-inner">Hens</span>
                      </span>
                    </span>
                  </span>
              </a>
            </div>
          </div>
          <div class="col-sm-6 col-lg-3 isotope-item brands">
            <div class="portfolio-item">
              <a href="#">
										<span class="thumb-info thumb-info-lighten border-radius-0">
											<span class="thumb-info-wrapper border-radius-0">
												<img src="assets/img/control/cucumbers.png" class="img-fluid border-radius-0" alt="">

												<span class="thumb-info-title">
													<span class="thumb-info-inner">Cucumbers</span>
												</span>
											</span>
										</span>
              </a>
            </div>
          </div>
          <div class="col-sm-6 col-lg-3 isotope-item brands">
            <div class="portfolio-item">
              <a href="#">
										<span class="thumb-info thumb-info-lighten border-radius-0">
											<span class="thumb-info-wrapper border-radius-0">
												<img src="assets/img/control/vegetables.png" class="img-fluid border-radius-0" alt="">

												<span class="thumb-info-title">
													<span class="thumb-info-inner">Veggies</span>
												</span>
											</span>
										</span>
              </a>
            </div>
          </div>
          <div class="col-sm-6 col-lg-3 isotope-item brands">
            <div class="portfolio-item">
              <a href="#">
										<span class="thumb-info thumb-info-lighten border-radius-0">
											<span class="thumb-info-wrapper border-radius-0">
												<img src="assets/img/control/lamp.png" class="img-fluid border-radius-0" alt="">

												<span class="thumb-info-title">
													<span class="thumb-info-inner">Lambs</span>
												</span>
											</span>
										</span>
              </a>
            </div>
          </div>
          <div class="col-sm-6 col-lg-3 isotope-item brands">
            <div class="portfolio-item">
              <a href="#">
										<span class="thumb-info thumb-info-lighten border-radius-0">
											<span class="thumb-info-wrapper border-radius-0">
												<img src="assets/img/control/Irish.png" class="img-fluid border-radius-0" alt="">
												<span class="thumb-info-title">
													<span class="thumb-info-inner">Potatoes</span>
												</span>
											</span>
										</span>
              </a>
            </div>
          </div>
          <div class="col-sm-6 col-lg-3 isotope-item brands">
            <div class="portfolio-item">
              <a href="#">
										<span class="thumb-info thumb-info-lighten border-radius-0">
											<span class="thumb-info-wrapper border-radius-0">
												<img src="assets/img/control/smoothies.png" class="img-fluid border-radius-0" alt="">
												<span class="thumb-info-title">
													<span class="thumb-info-inner">Smoothies</span>
												</span>
											</span>
										</span>
              </a>
            </div>
          </div>
          <div class="col-sm-6 col-lg-3 isotope-item brands">
            <div class="portfolio-item">
              <a href="#">
										<span class="thumb-info thumb-info-lighten border-radius-0">
											<span class="thumb-info-wrapper border-radius-0">
												<img src="assets/img/control/fish.png" class="img-fluid border-radius-0" alt="">
												<span class="thumb-info-title">
													<span class="thumb-info-inner">Fish</span>
												</span>
											</span>
										</span>
              </a>
            </div>
          </div>
          <div class="col-sm-6 col-lg-3 isotope-item brands">
            <div class="portfolio-item">
              <a href="#">
										<span class="thumb-info thumb-info-lighten border-radius-0">
											<span class="thumb-info-wrapper border-radius-0">
												<img src="assets/img/control/leaves.png" class="img-fluid border-radius-0" alt="">
												<span class="thumb-info-title">
													<span class="thumb-info-inner">Leaves</span>
												</span>
											</span>
										</span>
              </a>
            </div>
          </div>

        </div>

      </div>
    </div>
  </section>

</div>
<app-bottombar></app-bottombar>
