<section class="section section-no-border section-white pb-0 m-0">
  <div class="container" style="background-image: url(assets/img/control/newsletter-bg.png); background-position: center center; background-repeat: no-repeat;">
    <div class="row">

      <div class="col-md-6 p-5 py-lg-5 my-lg-5 d-sm-none d-lg-block" style="">
        <div class="pt-lg-5 m-lg-5">
          <img class="mb-5 font-weight-bold" alt="Logo" width="auto" height="auto" data-sticky-width="82" data-sticky-height="36" data-sticky-top="0" src="assets/img/control/logo.svg">

          <p class="text-dark font-weight-bold"><a  style="color: black !important; text-decoration: none;"  href="tel:+234 908 832 9373">+234 908 832 9373</a>
            <!-- <br>+234 908 832 9373 -->
          </p>
          <p class="text-dark font-weight-bold">contact@nazefarms.com <br> info@nazefarms.com</p>
          <ul class="social-icons">
            <!-- <li class=""><a href="http://www.facebook.com/" target="_blank" title="Facebook"><i class="fa fa-facebook"></i></a></li>
            <li class="social-icons-twitter"><a href="http://www.twitter.com/" target="_blank" title="Twitter"><i class="fa fa-twitter"></i></a></li> -->
            <li class="social-icons-instagram"><a href="https://www.instagram.com/nazefarmsng" target="_blank" title="Instagram"><i class="fa fa-instagram"></i></a></li>
          </ul>
          <p>© Naze Farms All rights reserved Copyrights 2022</p>
        </div>
      </div>
      <div class="col-md-5 bg-green rounded p-5 py-lg-5 text-center" style="height: 700px; border-radius: 30px 30px 0 0 !important;">
        <!--								<div>-->
        <h2 class="font-weight-bold text-9 text-white mt-lg-5 mb-lg-5  mb-0 appear-animation appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-duration="750" data-plugin-options="{'accY': -200}">
          Be informed! <br>
          <span class="font-weight-semibold">Sign up for newsletter</span>
        </h2>
        <p class=" text-white text-4 mt-2 text-mint mb-lg-5 appear-animation appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="400" data-appear-animation-duration="750">
          Be the first to get all the exciting updates about Naze Farms when you sign up to receive our newsletters!
        </p>
        <app-newsletter></app-newsletter>
        <!--								</div>-->
      </div>
      <div class="col-md-6 p-5 py-lg-5 my-lg-5 d-sm-block d-lg-none" style="background-image: url(assets/img/control/newsletter-bg.png); background-position: center center; background-repeat: no-repeat;">
        <div class="pt-lg-5 m-lg-5">
          <img class="mb-5 font-weight-bold" alt="Logo" width="auto" height="auto" data-sticky-width="82" data-sticky-height="36" data-sticky-top="0" src="assets/img/control/logo.svg">

          <p class="text-dark font-weight-bold"><a  style="color: black !important; text-decoration: none;"  href="tel:+234 908 832 9373">+234 908 832 9373</a></p>
          <p class="text-dark font-weight-bold">contact@nazefarms.com <br> info@nazefarms.com</p>
          <ul class="social-icons">
            <!-- <li class=""><a href="http://www.facebook.com/" target="_blank" title="Facebook"><i class="fa fa-facebook"></i></a></li>
            <li class="social-icons-twitter"><a href="http://www.twitter.com/" target="_blank" title="Twitter"><i class="fa fa-twitter"></i></a></li> -->
            <li class="social-icons-instagram"><a href="https://www.instagram.com/nazefarmsng" target="_blank" title="Instagram"><i class="fa fa-instagram"></i></a></li>
          </ul>
          <p>© Naze  All rights reserved Copyrights 2022</p>
        </div>
      </div>
    </div>
  </div>
</section>
