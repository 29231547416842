import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import {SharedModule} from './shared/shared.module';
import {RoutingModule} from './routing/routing.module';
import { ConnectComponent } from './connect/connect.component';
import { HttpClientModule } from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AboutComponent } from './about/about.component';
import { ServicesComponent } from './services/services.component';
import { GalleryComponent } from './gallery/gallery.component';
import {TopbarComponent} from './shared/components/topbar/topbar.component';
import {BottombarComponent} from './shared/components/bottombar/bottombar.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ConnectComponent,
    AboutComponent,
    ServicesComponent,
    GalleryComponent
  ],
  imports: [
    BrowserModule, RoutingModule, SharedModule, HttpClientModule, FormsModule, ReactiveFormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})

export class AppModule {

}
