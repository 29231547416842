import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-top-bar-all',
  templateUrl: './top-bar-all.component.html',
  styleUrls: ['./top-bar-all.component.css']
})
export class TopBarAllComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
